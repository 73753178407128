import {PurchaseOrder, PurchaseOrderItem} from "../../../api/Logistics";
import {
    AdjustCol,
    CentsCol, DateCol, Nullable,
    NumberCol,
    StringCol,
    Table, TableSelectionContext, ViewOnly
} from "../../../misc/scroller/Table";
import {api} from "../../../api/API";
import {getAll} from "../../project/shopdrawing/openings/Openings";
import {removeRowOption, RowOptionCol} from "./RowOptions";
import {ItemsFooter} from "./ItemsFooter";
import {useContext, useEffect} from "react";
import {purchasingPopoutPanel} from "./PurchaseOrderList";
import {useSyncedRef} from "../../../misc/SyncedRef";
import {green, grey, red} from "@mui/material/colors";

export const purchaseOrderItemsTableName = "purchasing.purchase-order-items"

export function Items(props: {
    order: PurchaseOrder;
}) {
    const ctx = useContext(TableSelectionContext);
    const reloadRef = useSyncedRef(ctx.refresh)

    useEffect(() => {
        const sub = purchasingPopoutPanel.fromClient.subscribe(v => {
            if(v === "refresh-po") {
                reloadRef.current(purchaseOrderItemsTableName);
            }
        })

        return () => sub.cancel();
    }, [reloadRef]);

    const editable = props.order.status === "draft"
    const showFrames = props.order.supplierSellsFrames;
    const showDoors = props.order.supplierSellsDoors;

    return (
        <>
            <Table<PurchaseOrderItem>
                name={purchaseOrderItemsTableName}
                cellCustomize={{
                    backgroundColor: (row) => {
                        if(row.qtyOutstanding === 0) {
                            return grey["200"]
                        }

                        return "white";
                    }
                }}
                columns={[
                    editable && RowOptionCol({
                        options: (dt) => [
                            removeRowOption(dt, {
                                refreshTables: [purchaseOrderItemsTableName],
                                onComplete: () => {
                                    purchasingPopoutPanel.sendToClient("refresh-to-source");
                                }
                            })
                        ],
                    }),
                    StringCol("Ref", "reference", 35),
                    ViewOnly(AdjustCol(StringCol("Description", "description", 200), {
                        maxWidth: 600,
                    })),
                    ViewOnly(StringCol("Code", "code", 80)),

                    // Frame columns
                    showFrames && ViewOnly(StringCol("Opening", "openingName", 100)),
                    showFrames && ViewOnly(StringCol("Floor", "openingFloor", 70)),
                    showFrames && ViewOnly(Nullable(DateCol("Due", "neededBy", "MMM D, YYYY"))),
                    showFrames && ViewOnly(StringCol("F. Series", "frameSeries", 100)),
                    showFrames && ViewOnly(StringCol("F. Material", "frameMaterial", 70)),
                    showFrames && ViewOnly(StringCol("F. Gauge", "frameGauge", 70)),
                    showFrames && ViewOnly(StringCol("F. Screen Elev", "frameScreenElev", 100)),
                    showFrames && ViewOnly(StringCol("F. Profile", "frameProfile", 70)),
                    showFrames && ViewOnly(StringCol("F. Jamb Depth", "frameJambDepth", 100)),
                    showFrames && ViewOnly(StringCol("F. Construction", "frameConstruction", 100)),
                    showFrames && ViewOnly(StringCol("F. Handing", "frameHanding", 70)),
                    showFrames && ViewOnly(StringCol("F. Hardware Preps", "frameHardwarePreps", 130)),
                    showFrames && ViewOnly(StringCol("F. Label", "frameLabel", 100)),
                    showFrames && ViewOnly(StringCol("F. Type", "frameType", 70)),

                    // Door columns
                    showDoors && ViewOnly(StringCol("D. Series", "doorSeries", 70)),
                    showDoors && ViewOnly(StringCol("D. Material", "doorMaterial", 100)),
                    showDoors && ViewOnly(StringCol("D. Finish", "doorFinish", 100)),
                    showDoors && ViewOnly(StringCol("D. Gauge", "doorGauge", 70)),
                    showDoors && ViewOnly(StringCol("D. Elev", "doorElevation", 70)),
                    showDoors && ViewOnly(StringCol("D. Core", "doorCore", 70)),
                    showDoors && ViewOnly(StringCol("D. Seam", "doorSeam", 70)),
                    showDoors && ViewOnly(StringCol("D. Handing", "doorHanding", 70)),
                    showDoors && ViewOnly(StringCol("D. Label", "doorLabel", 120)),

                    NumberCol("Qty", "qty", 60),
                    CentsCol("Unit Amount", "unitPriceCents"),
                    ViewOnly({
                        width: 100,
                        name: "Status",
                        render: (v: PurchaseOrderItem, col: any) => {
                            if(v.qtyBackOrdered > 0) {
                                return <span style={{color: red["800"], textDecoration: "underline"}}>{v.qtyBackOrdered} on backorder</span>
                            }

                            if(v.qtyPendingReceiving > 0) {
                                return <span style={{color: red["800"], textDecoration: "underline"}}>{v.qtyPendingReceiving} pending receiving</span>
                            }

                            if(v.qtyOutstanding > 0) {
                                return `${v.qtyOutstanding} outstanding`;
                            }

                            return <span style={{color: green["800"]}}>Received</span>
                        }
                    }),
                ]}
                fetch={ctx => getAll(ctx, offset => api.logistics.listPurchaseOrderItems({
                        offset,
                        purchaseOrder: props.order.id,
                        type: "purchase-order",
                    }))}
                fetchDeps={[props.order.id]}
                onChange={v => api.logistics.updatePurchaseOrderItem(v)}
                noHorizontalScroller
                noFooter
            />
            <ItemsFooter value={props.order} />
        </>
    )
}