import {Button} from "@mui/material";
import {useCheckboxSelection} from "../../project/shopdrawing/release/Checkbox";
import {TableSelectionContext, useTableData} from "../../../misc/scroller/Table";
import {routePopupPanel} from "./RouteList";
import { DriverRoutePendingItem} from "../../../api/Logistics";
import {api} from "../../../api/API";
import {useSnackbar} from "../../../misc/Snackbar";
import {pickupTableName} from "./PickupTable";
import {dropTableName} from "./DropTable";
import {useContext, useRef} from "react";

export function AddItemsButton(props: {
    pickup: boolean;
    route?: number
}) {
    const ids = useCheckboxSelection();
    const tbls = useContext(TableSelectionContext)
    const items = useTableData<DriverRoutePendingItem>(props.pickup ? pickupTableName : dropTableName)
    const snack = useSnackbar();
    const dblClickActive = useRef(false)

    return (
        <Button disabled={ids.length === 0 || !props.route} variant="contained" color="primary" size="small" onClick={async () => {
            if(!props.route) return;

            if(dblClickActive.current) return;
            dblClickActive.current = true;


            const data = items
                .filter(v => ids.indexOf(v.id) !== -1)
                .map(v => {
                    return {
                        pickupPo: v.pickupPo,
                        // dropProject: v.dropProject, bring to warehouse by default
                        pickupDelivery: v.pickupDelivery,
                    }

                })

            try {
                await api.logistics.addRouteItems({
                    route: props.route,
                    items: data,
                });

                tbls.refresh(props.pickup ? pickupTableName : dropTableName);
                routePopupPanel.sendToServer("refresh-route");
            } catch (e: any) {
                snack.error(e.toString())
            }

            dblClickActive.current = false;
        }}>
            Add Items
        </Button>
    )
}