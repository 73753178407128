import {
    DateCol,
    Nullable, Option,
    sortString,
    StringCol,
    Table, ViewOnly
} from "../../../misc/scroller/Table";
import {api} from "../../../api/API";
import {getAll} from "../../project/shopdrawing/openings/Openings";
import {PurchaseOrder} from "../../../api/Logistics";
import {first, formatCents} from "nate-react-api-helpers";
import {SectionHeader} from "../../../misc/SectionHeader";
import {brown, green, grey, orange, purple, yellow} from "@mui/material/colors";
import {useEffect, useState} from "react";
import { SmallSearchBox } from "./SmallSearchBox";
import {ProjectFilter} from "./ProjectFilter";
import {Grid} from "@mui/material";
import {removeRowOption, RowOptionCol} from "./RowOptions";
import { CreatePurchaseOrder } from "./CreatePurchaseOrder";
import { CellWithBg } from "./CellWithBg";
import {useNavigate} from "react-router-dom";
import {Container} from "../../../misc/Container";
import {WhiteColumn} from "./WhiteColumn";
import {SmallGreyButton} from "../../../misc/SmallGreyButton";
import {OpenInNew} from "@mui/icons-material";
import {ToSourceProps} from "./PartsToSource";
import { PopoutInteractor } from "../../../misc/PopoutInteractor";

export const purchaseOrderStatuses = [
    {
        display: "Draft",
        value: "draft",
    },
    {
        display: "Submitted",
        value: "submitted",
    },
    {
        display: "Ready for pickup",
        value: "ready-for-pickup",
    },
    {
        display: "Assigned Route",
        value: "assigned-route",
        disabled: true,
    },
    {
        display: "In Truck",
        value: "in-truck",
        disabled: true,
    },
    {
        display: "Pending Receiving",
        value: "pending-receiving",
        disabled: true,
    },
    {
        display: "Received",
        value: "complete",
    }, {
        display: "Back Ordered",
        value: "backorder",
    },
];

export const pickup = "pickup"

export const deliveryMethods = [
    {
        display: "Pickup",
        value: pickup,
    },
    {
        display: "Delivery",
        value: "delivery",
    }
];

export const purchaseOrderTableName = "purchasing.purchase-orders";
export const purchasingPopoutPanel = new PopoutInteractor<ToSourceProps | "refresh-to-source", "refresh-po">("purchasing.popout", "/logistics/purchasing/to-source-popout");

export function PurchaseOrderList(props: {}) {
    const [search, setSearch] = useState("")
    const [project, setProject] = useState<Option|null>(null)
    const navigate = useNavigate();

    useEffect(() => {
        purchasingPopoutPanel.sendToClient({
            isDraft: false,
        });

        return () => {
            purchasingPopoutPanel.close();
        }
    }, []);

    return <Container>
        <WhiteColumn>
            <SectionHeader name="Purchasing" right={
                <Grid container spacing={1} flexWrap="nowrap">
                    <Grid item>
                        <SmallGreyButton onClick={() => purchasingPopoutPanel.open()} startIcon={<OpenInNew />}>
                            To Source
                        </SmallGreyButton>
                    </Grid>
                    <Grid item>
                        <ProjectFilter value={project} onChange={setProject} />
                    </Grid>
                    <Grid item>
                        <SmallSearchBox value={search} onChange={setSearch} fullWidth />
                    </Grid>
                </Grid>
            } />

            <Table<PurchaseOrder>
                name={purchaseOrderTableName}
                columns={[
                    RowOptionCol({
                        options: (dt) => [
                            removeRowOption(dt, {
                                refreshTables: [purchaseOrderTableName],
                                confirm: true,
                            })
                        ],
                    }),
                    ViewOnly(StringCol("PO #", "poNumber", 100)),
                    ViewOnly(StringCol("Supplier", "supplierName", 100)),
                    {
                        name: "Project",
                        width: 200,
                        render: (dt) => dt.linkedProjects?.map(v => v.name).join(", ") || "",
                    },
                    ViewOnly(Nullable(DateCol("Submitted", "submittedAt", "MMM D, YYYY", 100))),
                    {
                        name: "Status",
                        render: (dt, col) => {

                            let bgColor = "";
                            let color = "inherit"
                            switch (dt.status) {
                                default:
                                case "draft":
                                    bgColor = orange["400"];
                                    break;
                                case "backorder":
                                    bgColor = purple["400"];
                                    color = "white"
                                    break;
                                case "submitted":
                                    color = green["800"]
                                    break;
                                case "ready-for-pickup":
                                    color = "white";
                                    bgColor = green["400"];
                                    break;
                                case "assigned-route":
                                    color = "white";
                                    bgColor = brown["400"];
                                    break;
                                case "in-truck":
                                    color = grey["900"];
                                    bgColor = yellow["400"];
                                    break;
                                case "complete":
                                    color = grey["400"];
                                    break;
                            }

                            return (
                                <CellWithBg color={color} backgroundColor={bgColor}>
                                    {first(purchaseOrderStatuses, s => s.value === dt.status)?.display}
                                </CellWithBg>
                            );
                        },
                        filter: (row, value) => {
                            value = value.toLowerCase();
                            const matches = purchaseOrderStatuses.filter(o => o.display.toLowerCase().indexOf(value) === 0).map(o => o.value);
                            return matches.indexOf(row.status) !== -1
                        },
                        sort: sortString("status"),
                        width: 70,
                    },
                    {
                        name: "Amount",
                        render: dt => formatCents(dt.totalCents),
                        alignRight: true,
                        width: 70,
                        filter: (row, value) => {
                            return formatCents(row.totalCents).indexOf(value) === 0;
                        },
                    },
                    StringCol("Notes", "notes", 100),
                ]}
                fetch={ctx => getAll(ctx, offset => api.logistics.listPurchaseOrders({
                    offset,
                    search,
                    project: project?.value || undefined,
                }))}
                onChange={input => api.logistics.updatePurchaseOrder(input)}
                fetchDeps={[search, project]}
                onRowClick={(item) => {
                    navigate("/logistics/purchasing/" + item.id);
                }}
                insert={{
                    modal: (onDone) => <CreatePurchaseOrder onDone={onDone} />,
                }}
            />
        </WhiteColumn>
        </Container>
}



